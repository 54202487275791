<template>
  <div class="shell-box">
    <div class="operation">
      <div class="operation-back" @click="handleGoPath('back')">
        <img
          src="../../assets/images/realTimePositioning/map-back.png"
          class="tab-icon"
          alt=""
          srcset=""
        />返回
      </div>
      <el-breadcrumb separator="/" class="breadcrumb">
        <el-breadcrumb-item :to="{ path: `/riskControlManagement` }"
          >风控管理</el-breadcrumb-item
        >
        <el-breadcrumb-item>巡检记录</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="form">
      <div class="container">
        <GeneralTable
          ref="GeneralTable"
          :column="column"
          tableName="riskControlInterventionRecord"
          :option="option"
          :dataList="dataList"
          :totalNum="totalNum"
          @search-change="getList"
          @export-data="exportData"
          @add-change="editDetail({})"
          @import-click="isImport = true"
        >
          <template slot="enable" slot-scope="{ item }">
            <el-tag type="success" v-if="item.enable">启用</el-tag>
            <el-tag type="danger" v-else>禁用</el-tag>
          </template>
          <template slot="operate" slot-scope="{ item }">
            <el-button
              class="polecy-button" style="color:rgba(246, 74, 45, 1)"
              @click.native.prevent="handleDel(item)"
              type="text"
              size="small"
              >删除</el-button
            >
          </template>
        </GeneralTable>
      </div>
    </div>
  </div>
</template>
<script>
import GeneralTable from "@/components/GeneralTable/index.vue";
import {
  interventionList,
  interventionDelete,
  interventionExport,
  userProject
} from "@/api/SystemData";
import {
  getDictList, //获取产品列表
  getDictType, //获取设备列表
} from "@/api/getManagementData.js";
import dayjs from "dayjs";
import { deleteInterveneData } from "@/api/riskData";
export default {
  name: "interventionRecord",
  components: { GeneralTable },
  data() {
    return {
      dataList: [],
      totalNum: 0,
      option: {
        isRefresh: true, // 刷新 @search-change
        isSearch: true,
        isExport: true, // 导入 @import-click
        isSelection: false,
        disFuzzySearch: true,
        isStripe: true, //
        pageSize: 10,
        searchList: [
          {
            label: "设备名称",
            prop: "plate",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "风险类型",
            prop: "dataType",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请选择",
            selectList: [
              { value: "", text: "全部" },
              { value: '1', text: "设备离线" },
              { value: '2', text: "设备故障" },
              { value: '3', text: "天气监控" },
              { value: '4', text: "工时分析" },
              { value: '5', text: "保单脱保" },
              { value: '6', text: "设备出险" },
              { value: '7', text: "驾驶行为" },
              { value: '8', text: "视频监控" },
              { value: '9', text: "报警监控" },
            ],
          },
          {
            label: "客户名称",
            prop: "customerName",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "项目名称",
            prop: "projectId",
            formType: "select",
            filterable: true,
            maxLength: 32,
            clearable: true,
            placeholder: "请选择",
            selectList: []
            // isPermissions: dataPermissions(`M20_${ this.$route.meta.policyType }-View`, "policyNo"),
          },
          {
            label: "处理方式",
            prop: "interveneWay",
            formType: "select",
            filterable: true,
            selectList: [
              { value: "", text: "全部" },
              { value: '确认无风险', text: "确认无风险" },
              { value: '误报', text: "误报" },
              { value: '风险点', text: "风险点" },
            ],
            clearable: true,
            placeholder: "请选择",
          },
          {
            label: "处理人",
            prop: "createUser",
            formType: "input",
            maxLength: 32,
            clearable: true,
            placeholder: "请输入",
          },
          {
            label: "处理时间",
            prop: "datetime",
            format: "yyyy.MM.dd",
            formType: "daterange",
            clearable: true,
            prop: ["beginDatetime", "endDatetime"],
          },
        ],
      },
      column: [
        {
          label: "序号",
          type: "index",
          isShow: true,
          fixed: "left",
        },
        {
          label: "设备名称",
          prop: "plate",
          isShow: true,
        },
        {
          label: "客户名称",
          prop: "customName",
          isShow: true,
        },
        {
          label: "项目名称",
          prop: "projectName",
          isShow: true,
        },
        {
          label: "风险类型",
          prop: "dataTypeCH",
          isShow: true,
        },
        {
          label: "处理人",
          prop: "createUser",
          isShow: true,
        },
        {
          label: "处理时间",
          prop: "createTime",
          isShow: true,
        },
        {
          label: "处理方式",
          prop: "interveneWay",
          isShow: true,
        },
        {
          label: "处理说明",
          prop: "remark",
          isShow: true,
        },
        {
          label: "操作",
          prop: "operate",
          isShow: true,
          width: 120,
          fixed: "right",
          isSlot: true,
        },
      ],
      typesOfConstructionMachineryList: [],
      brandInfoPageList: [],
      partBrandList: [],
      deviceTypeList: [],
      isExpansion: false,
      isImport: false,
      isAddVisible: false,
      editData: {},
      oldSearchData: {},
      operateTitle: "添加",
      machineryEquipmentTypeList: [],
      machineryProductTypeList: [],
      ruleForm: {
        deviceType: "",
        deviceCategory: "",
        topLevelFlag: true,
      },
    };
  },
  created() {
    this.init();
  },
  computed: {},
  methods: {
    async init() {
      try {
        let res = await userProject({ userId: this.$store.state.userInfo.userId });
        if (res && res.data && res.data.length > 0) {
          let ind = this.option.searchList.findIndex(
            (val) => val.label === "项目名称"
          );
          this.option.searchList[ind].selectList = res.data.map(
            (val) => {
              return {
                text: val.projectName,
                value: val.projectId,
              };
            }
          );
          this.option.searchList[ind].selectList.unshift({
            text: "全部",
            value: "",
          });
        }else {
        }
      } catch (error) {
      }
    },
    getList(v, pageNum, pageSize, n, type) {
      if (v.beginDatetime && v.beginDatetime.split(" ").length === 1) {
        v.beginDatetime =
          dayjs(v.beginDatetime).format("YYYY-MM-DD") + " 00:00:00";
        v.endDatetime = dayjs(v.endDatetime).format("YYYY-MM-DD") + " 23:59:59";
      }
      // , deviceType: this.deviceTypeList.toString()
      // , partBrand: this.partBrandList.toString()
      this.oldSearchData = Object.assign(
        { },
        {
          ...v,
          pageIndex: pageNum,
          pageSize: pageSize,
          fuzzyLookupField: v.keyword,
        }
      );
      interventionList(this.oldSearchData).then((res) => {
        this.dataList = res.data.records;
        this.totalNum = res.data.total;
      });
    },
    handleClassification(e, type) {
      // this[type].includes(e) ? this[type].splice(this[type].findIndex ,1) : this[type].push(e);
      this[type].includes(e) ? (this[type] = []) : (this[type] = [e]);
      this.changeGetList();
    },
    changeGetList() {
      this.$refs.GeneralTable.getList();
    },
    goDetail(e) {
      console.log("详情");
      this.$router.push({
        path: "/routerProjectManagement/projectDetails?id=" + e.id,
      });
    },
    exportData(e) {
      interventionExport({
        ...this.oldSearchData,
        ids: e.map((val) => val.id).toString(),
      });
    },
    handleDel(e) {
      this.$confirm("确定删除该记录?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteInterveneData({ id: e.id }).then((res) => {
          this.changeGetList();
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
      });
    },
    editDetail(e) {
      this.$router.push({
        path: `/routerProjectManagement/addProject${e.id ? "?id=" + e.id : ""}`,
      });
    },
    // 获取设备类型列表
    getMachineryEquipmentTypeList() {
      getDictType({
        dictType: this.machineryProductTypeList.find(
          (val) => val.dictName == this.ruleForm.deviceType
        ).dictType,
      })
        .then((res) => {
          if (res.flag === 1) {
            this.machineryEquipmentTypeList = res.obj;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 选择产品类型
    selectMachineryProductType() {
      this.ruleForm.deviceCategory = "";
      this.getMachineryEquipmentTypeList();
    },
    handleGoPath(type) {
      if (type == "back") {
        this.$router.go(-1);
      } else {
        this.$router.push({
          path: type,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.search-filter {
  position: relative;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  margin-bottom: 10px;
  overflow: hidden;
  .product,
  .brand {
    display: flex;
    .label {
      width: 100px;
      height: 28px;
      line-height: 28px;
      white-space: nowrap;
    }
    .list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      .item {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        padding: 0 12px;
        border: 1px solid #4278c9;
        margin: 0 15px 15px 0;
        cursor: pointer;
        border-radius: 4px;
        // &:hover {
        //   color: #4278c9;
        //   background-color: #eff6ff;
        // }
      }
      .check-item {
        color: #ffffff;
        background-color: #4278c9;
        border: 1px solid #4278c9;
      }
    }
  }
  .expansion-box {
    position: absolute;
    bottom: 2px;
    left: 0;
    right: 0;
    cursor: pointer;
    font-size: 14px;
    color: #0080ff;
    text-align: center;
  }
}
.enableStatus1 {
}
.enableStatus2 {
}
.slot-list {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 250px;
    padding: 10px;
    border: 1px solid #dee3e6;
    border-radius: 6px;
    margin: 0 15px 15px 0;
    .img {
      width: 230px;
      height: 230px;
      margin-bottom: 8px;
    }
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 16px;
      margin-bottom: 8px;
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 8px;
      .modelNum {
        font-size: 14px;
      }
      .price {
        font-size: 18px;
        color: #fda835;
        font-weight: bold;
      }
    }
    .tags {
      display: flex;
      align-items: center;
      .tag {
        margin-right: 8px;
        padding: 5px 8px;
        background-color: #f2f7fd;
        color: #3470ff;
        border-radius: 5px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}
.shell-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 10px;
  .form {
    flex: 1;
    overflow: auto;
    padding-bottom: 20px;
  }
  .breadcrumb {
    padding: 10px 0;
    ::v-deep .el-breadcrumb__inner.is-link {
      color: rgba(0, 128, 255, 1);
    }
  }
  .operation {
    display: flex;
    margin-bottom: 8px;
    &-back {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 72px;
      height: 33px;
      font-size: 14px;
      color: #ffffff;
      margin-right: 16px;
      background: #4278c9;
      border-radius: 17px 17px 17px 17px;
      img {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }
    }
  }
}
</style>
