import { appConfig } from "@/config/appConfig";
import { createHttp } from "@/api/HttpHelper";
import { createMJHttp } from "@/api/MJRequest";
import store from "@/store";
const axios = createHttp(appConfig.base2GUrl);
const MJAxios = createMJHttp(appConfig.MJBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);
const YWAxios = createHttp(appConfig.YWBaseUrl, {
  data: { MJUserId: store.state.userInfo.MJUserId },
  params: { MJUserId: store.state.userInfo.MJUserId },
});
//设备在线-告警数据
export const getEquipmentOnlineData = (params) => {
  return YWAxios.axios({
    url: "/basic-risk/risk/getDeviceOnlineAlarm",
    method: "GET",
    params,
  });
};
// 根据项目获取本身以及下级客户名称列表
export const getProjectCustomer = (data) => {
  return YWAxios.axios({
    url: "/basic-risk/risk/pageProjectCustomer",
    method: "POST",
    data,
  });
};
//获取开工情况
export const getWorkCondition = (params) => {
  return YWAxios.axios({
    url: "/basic-risk/risk/getWorkSituation",
    method: "GET",
    params,
  });
};
//获取风控在线数据和风控培训列表
export function getRiskTrainOnlineData(data) {
  return YWAxios.axios({
    url: "/basic-risk/risk/riskOnlineTrainList",
    method: "POST",
    data,
  });
}
// 获取风控数据2.0 项目指标、保单在线、服务在线
export function getRiskData2(data) {
  return YWAxios.axios({
    url: "/basic-risk/risk/riskPageV2",
    method: "POST",
    data,
  });
}

//获取案件列表
export function getCaseList(data) {
  return YWAxios.axios({
    url: "/basic-risk/risk/projectCaseList",
    method: "POST",
    data,
  });
}
//获取案件分析列表
export function getCaseAnalyzeList(data) {
  return YWAxios.axios({
    url: "/basic-risk/risk/caseAnalyze",
    method: "POST",
    data,
  });
}

// 获取工时分布
export function getWorkTimeDistribution(params) {
  return YWAxios.axios({
    url: "/basic-risk/risk/getHourCount",
    method: "GET",
    params,
  });
}

// 根据条件获取设备地址
export function getDevAddressCode(params) {
  return YWAxios.axios({
    url: "/basic-risk/risk/getVehicleLatestAddr",
    method: "GET",
    params,
  });
}
// 获取工时分布-列表
export function getWorkTimesList(params) {
  return YWAxios.axios({
    url: "/basic-risk/risk/getWorkHourList",
    method: "GET",
    params,
  });
}
// 获取工时分布-图表
export function getWorkTimesChart(params) {
  return YWAxios.axios({
    url: "/basic-risk/risk/getWorkHourGraph",
    method: "GET",
    params,
  });
}
// 获取工时分布-详情
export function getWorkTimesDetails(params) {
  return YWAxios.axios({
    url: "/basic-risk/risk/getWorkHourDetail",
    method: "GET",
    params,
  });
}
// 导出工时分布数据
export function exportWorkTimes(data) {
  return YWAxios.axios({
    url: "/basic-risk/risk/exportWorkHour",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
}

// 获取安全指数数据
export function getSafetyIndex(data) {
  return YWAxios.axios({
    url: "/basic-risk/risk/getSecurityIndex",
    method: "POST",
    data,
  });
}

// 获取告警记录
export function getAlarmRecord(params) {
  return YWAxios.axios({
    url: "/basic-risk/risk/getRiskAlarm",
    method: "GET",
    params,
  });
}
//获取风控培训回顾详情
export function getRiskTrainReview(data) {
  return YWAxios.axios({
    url: "/basic-risk/risk/riskTrainReview",
    method: "POST",
    data,
  });
}

//获取风控培训回顾详情列表
export function getRiskTrainDetailList(data) {
  return YWAxios.axios({
    url: "/basic-risk/risk/riskTrainPage",
    method: "POST",
    data,
  });
}

//风控培训导出导出
export function exportRiskTrain(data) {
  return YWAxios.axios({
    url: "/basic-risk/risk/riskTrainDownload",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
}

//获取告警记录列表
export function getAlarmRecordList(data) {
  return YWAxios.axios({
    url: "/basic-risk/riskAlarmRecord/page",
    method: "POST",
    loading: true,
    data,
  });
}

//获取干预记录列表
export function getInterventionRecordList(data) {
  return YWAxios.axios({
    url: "/basic-risk/riskIntervene/page",
    method: "POST",
    loading: true,
    data,
  });
}

// 理赔列表-全部导出
export function RiskinsClaimsCaseExportAll(params) {
  return MJAxios.axios({
    url: `/ins-policy/insClaimsCase/exportCaseRisk`,
    method: "GET",
    responseType: "blob",
    timeout: 1800000,
    loading: true,
    params,
  });
}
//干预信息导出
export function exportInterveneData(data) {
  return YWAxios.axios({
    url: "/basic-risk/riskIntervene/export",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
}
//告警数据导出
export function exportAlarmData(data) {
  return YWAxios.axios({
    url: "/basic-risk/riskAlarmRecord/export",
    method: "POST",
    responseType: "blob",
    loading: true,
    timeout: 1800000,
    data,
  });
}
//添加干预信息
export function addInterveneData(data) {
  return YWAxios.axios({
    url: "/basic-risk/riskIntervene/add",
    method: "POST",
    loading: true,
    data: { ...data, createUser: store.state.userInfo.name },
  });
}
//删除干预信息
export function deleteInterveneData(params) {
  return YWAxios.axios({
    url: "/basic-risk/riskIntervene/delete",
    method: "GET",
    loading: true,
    params,
  });
}

//获取所有车牌
export function getAllVehicle(params) {
  return YWAxios.axios({
    url: "/basic-risk/riskIntervene/getVehicleByFrameNo",
    method: "GET",
    loading: true,
    params,
  });
}

//告警记录详情
export function getAlarmRecordDetail(params) {
  return YWAxios.axios({
    url: "/basic-risk/riskAlarmRecord/detail",
    method: "GET",
    loading: true,
    params,
  });
}

//短信通知
export function sendSms(data) {
  return YWAxios.axios({
    url: "/basic-risk/riskAlarmRecord/message",
    method: "POST",
    loading: true,
    data,
  });
}

// 获取安全指数-评分记录
export function getSafetyIndexRecord(data) {
  return YWAxios.axios({
    url: "/basic-risk/risk/getSecurityIndexRecord",
    method: "POST",
    data,
  });
}

//查询车辆是否在线
export function getVehicleOnline(params) {
  // vehicleId
  return axios.axios({
    url: "/vehicle/getVehicleState",
    method: "GET",
    params,
  });
}

// // 获取安全指数-评分记录
// export function riskTrainPage(data) {
//   return YWAxios.axios({
//     url: "/basic-risk/risk/riskTrainPage",
//     method: "POST",
//     data,
//   });
// }

// 大屏集合
export function projectLSList(data) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/list",
    method: "POST",
    data,
    loading: true,
  });
}

// 项目绑定
export function projectLSProjectBind(data) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/projectBind",
    method: "POST",
    data,
    loading: true,
  });
}

// 查询项目绑定信息
export function projectBindList(params) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/projectBindList",
    method: "GET",
    params,
  });
}

// 项目管理集合
export function projectV2List(params) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/projectV2List",
    method: "GET",
    params,
  });
}

// 添加项目
export function saveOrUpdate(data) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/saveOrUpdate",
    method: "POST",
    data,
    loading: true,
  });
}

// 项目内容设置
export function projectLSContent(data) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/content",
    method: "POST",
    data,
    loading: true,
  });
}

// 查询项目管理集合
export function projectLSContentList(params) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/contentList",
    method: "GET",
    params,
  });
}

// 项目 删除
export function projectLSDelete(params) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/delete",
    method: "get",
    params,
    loading: true,
  });
}

// 布控地图报警信息
export function alarmCarousel(params) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/alarmCarousel",
    method: "get",
    params,
  });
}

// 布控地图车辆信息
export function projectLSBKMap(params) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/BKMap",
    method: "get",
    params,
  });
}

// 布控地图数据概览、报警分析、安全指数
export function dataOverview(params) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/dataOverview",
    method: "get",
    params,
  });
}

// 事件地图 告警
export function eventMap(params) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/eventMap",
    method: "get",
    params,
  });
}

// 事件地图 告警统计
export function eventMapStatistics(params) {
  return YWAxios.axios({
    url: "/basic-risk/projectLS/eventMapStatistics",
    method: "get",
    params,
  });
}

// 查项目下所有车辆跟车次数
export function followCount(params) {
  return YWAxios.axios({
    url: "/basic-risk/car/followCount",
    method: "get",
    params,
  });
}

// 跟车记录分页 - 风控
export function followPage(params) {
  return YWAxios.axios({
    url: "/basic-risk/car/page",
    method: "get",
    params,
  });
}

// 导出跟车记录
export function carFollowingExport(params) {
  return YWAxios.axios({
    url: "/basic-risk/car/carFollowingExport",
    method: "GET",
    responseType: "blob",
    loading: true,
    timeout: 18000000,
    params,
  });
}

// 跟车记录添加编辑 - 风控
export function followingAddOrUpdate(data) {
  return YWAxios.axios({
    url: "/basic-risk/car/addOrUpdate",
    method: "post",
    data,
  });
}

// 跟车记录删除 - 风控
export function followDelete(params) {
  return YWAxios.axios({
    url: "/basic-risk/car/delete",
    method: "get",
    params,
  });
}



